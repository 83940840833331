import * as React from "react"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Page from "../elements/Page.js"
import Depoimento from "../elements/Depoimento"

import EmpresasParceiras from "../section/EmpresasParceiras.js"
import FormContatoImageSection from "../section/FormContatoImageSection.js"

import ContainerWithBg from "../helpers/ContainerWithBg.js"
import Container from "../helpers/Container.js"

export default function ConsultoriaPage(props){

  const { featuredImage, title, yoastData, content } = props.pageContext
  const image = getImage(featuredImage.localFile)

  // Sobre
  const sobre = content.comunicacaoConsultoriaConteudoDepoimentosSobre
  let sobreImageSrc = sobre.comunicacaoConsultoriaConteudoDepoimentosSobreImagem

  if(!sobreImageSrc) sobreImageSrc = { localFile: "../../images/post-image.png", altText: "Placeholder Image"}
  const sobreImage = getImage(sobreImageSrc.localFile)

  // depois
  const depois = content.comunicacaoConsultoriaConteudoDepois
  let depoisImageSrc = depois.comunicacaoConsultoriaConteudoDepoisImagem

  if(!depoisImageSrc) depoisImageSrc = { localFile: "../../images/post-image.png", altText: "Placeholder Image"}
  const depoisImage = getImage(depoisImageSrc.localFile)

  return (
    <Page>
      <Seo 
        title={yoastData.title}
        post={yoastData} 
      />

      <ContainerWithBg
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-dynamic"
        className="container-md tac mh-27r line-lenght-large ai-c jc-c dfc c-white p-r z1"
        style={{backgroundImage: `url(${image.images.fallback.src}`}}
      >
        <span className="tag bg-color-golden c-purple m-2 c-p db ttu mb-2 bold">
          Projetos
        </span>
        <h1 className="h1 mb-0">{title}</h1>
      </ContainerWithBg>
      
      
      {/* Conteúdo lateral esquerda */}      
      
      <Container className="container-fluid c-white">
        <div className="row">
          <div className="col-md-6 order-2 order-md-1 p-0 mt-4 mt-lg-8 d-flex flex-column">
            <GatsbyImage image={sobreImage} className="" objectFit="cover" alt={sobreImageSrc.altText} />
            <div className="p-4 p-lg-8">
              <div className="col-size-720">
                <h2 className="h2 c-purple">{sobre.comunicacaoConsultoriaConteudoDepoimentosSobreTitulo}</h2>
                <div dangerouslySetInnerHTML={{ 
                    __html: sobre.comunicacaoConsultoriaConteudoDepoimentosSobreConteudo 
                  }}
                  className="c-grey"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 order-1 order-md-2 bg-color-purple-4 p-4 p-lg-8">
            <div className="col-size-720">
              <div className="packing py-3">
                <h2 className="h2 c-purple title-left-arrow ms-8 ms-lg-0">Como é o trabalho?</h2>
                <div dangerouslySetInnerHTML={{ 
                    __html: content.comunicacaoConsultoriaConteudoTrabalho
                  }} 
                  className="c-grey" 
                />
              </div>
              <div className="packing py-3">
                <h2 className="h2 c-purple title-left-arrow ms-8 ms-lg-0">Entregas</h2>
                <div dangerouslySetInnerHTML={{ 
                    __html: content.comunicacaoConsultoriaConteudoEntregas
                  }} 
                  className="c-grey list-default" 
                />
              </div> 
            </div>
          </div>
        </div>
      </Container>
      
      <Container className="container-fluid c-white">
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 bg-color-golden p-8">
            <div className="col-size-720">
              <h2 className="h2 c-purple">{depois.comunicacaoConsultoriaConteudoDepoisTitulo}</h2>
              <div dangerouslySetInnerHTML={{ 
                  __html: depois.comunicacaoConsultoriaConteudoDepoisConteudo
                }} 
                className="c-grey" 
              />
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 p-0 bg-color-purple-1 d-flex flex-column justify-content-center">
            <GatsbyImage image={depoisImage} className="w-100 mh-27r" objectFit="cover" alt={depoisImageSrc.altText} />
          </div>
        </div>
      </Container>
      
      { content.comunicacaoConsultoriaConteudoDepoimentos &&

        <Container className="container-fluid c-white p-4 p-lg-8">
          <div className="row justify-content-center">
            <h2 className="tac h2 c-purple mb-6">{
              content.comunicacaoConsultoriaConteudoDepoimentos.length === 1 ? `Depoimento` : `Depoimentos`
            }</h2>
            { content.comunicacaoConsultoriaConteudoDepoimentos &&
              content.comunicacaoConsultoriaConteudoDepoimentos.map((dep, index) => (
                <Depoimento 
                  key={index}
                  text={dep.comunicacaoConsultoriaConteudoDepoimentosDepoimento}
                  jobTitle={dep.comunicacaoConsultoriaConteudoDepoimentosTitulo}
                  company={dep.comunicacaoConsultoriaConteudoDepoimentosNome}
                  picture={dep.comunicacaoConsultoriaConteudoDepoimentosFoto}
                  video={dep.comunicacaoConsultoriaConteudoDepoimentosVideo}
                />
              ))
            }
          </div>
        </Container>

      }      

      <EmpresasParceiras />
      <FormContatoImageSection />
    </Page>
  )
}